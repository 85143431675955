import React from "react";
import styled from "styled-components";
import { ArrowRight } from "../../components/icons";

const StylesPreliminaryPrice = styled.div`
  width: 508px;
  height: 584px;
  padding: 0 32px;
  text-align: center;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  border-radius: 24px;

  @media (max-width: 991.98px) {
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: 170% 50%;
    width: 100%;
    height: 256px;
    margin-top: 33px;
    padding: 42px 56px;

    text-align: start;
  }

  @media (max-width: 767.98px) {
    background-size: cover;
    background-position: center 20%;
    margin-top: 28px;
    height: 264px;
    padding: 17px 18px;
    text-align: center;
  }

  .preliminary-price__info {
    @media (max-width: 991.98px) {
      max-width: 260px;
    }

    @media (max-width: 767.98px) {
      max-width: unset;
    }
  }

  .preliminary-price__title {
    font-size: 20px;
    line-height: 30px;
    color: #5b5b5b;
    margin-top: 56px;

    @media (max-width: 991.98px) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 0;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .preliminary-price__price {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    margin-top: 12px;
    margin-bottom: 36px;

    @media (max-width: 991.98px) {
      margin-top: 16px;
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 48px;
    }

    @media (max-width: 767.98px) {
      font-size: 24px;
      line-height: 27px;
      margin-top: 8px;
      margin-bottom: 22px;
    }
  }

  .preliminary-price__content {
    font-size: 20px;
    line-height: 30px;
    color: #5b5b5b;
    margin-top: 72px;
    margin-bottom: 34px;

    @media (max-width: 991.98px) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 70px;
    }

    @media (max-width: 767.98px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 22px;
    }

    @media (min-width: 1160px) and (max-width: 1218px) {
      margin-bottom: 64px;
    }
  }

  .preliminary-price__contact {
    position: relative;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #2c6dec;
    cursor: pointer;

    @media (max-width: 991.98px) {
      justify-content: left;
    }

    @media (max-width: 767.98px) {
      justify-content: center;
    }

    svg {
      position: absolute;
      right: -19px;
      top: 5px;
    }
  }
`;

interface Props {
  image: string;
  title: string;
  content: number | string;
  onRequestCall: () => void;
}

const PreliminaryPrice = ({ image, title, content, onRequestCall }: Props) => {
  const showPrice = typeof content === "number";

  return (
    <StylesPreliminaryPrice style={{ backgroundImage: `url(${image})` }}>
      <div className="preliminary-price__info">
        {showPrice ? (
          <>
            <p className="preliminary-price__title">{title}</p>
            <p className="preliminary-price__price">
              до {(Math.round((content as number) * 100) / 100).toFixed(2)} руб.
            </p>
          </>
        ) : (
          <p className="preliminary-price__content">{content}</p>
        )}
        <a className="preliminary-price__contact" onClick={onRequestCall}>
          Связаться с нами
          <ArrowRight />
        </a>
      </div>
    </StylesPreliminaryPrice>
  );
};

export { PreliminaryPrice };
