import { useStaticQuery, graphql } from "gatsby";

export const useRepairPageData = () => {
  const { prismicRepair } = useStaticQuery(
    graphql`
      query {
        prismicRepair {
          data {
            description {
              text
            }
            info {
              text
            }
            phone_models {
              screen
              charging
              case
              water
              unlock
              button
              model {
                text
              }
            }
            phone_title {
              text
            }
            preliminary_cost_block_image {
              url
              alt
            }
            preliminary_cost_block_title {
              text
            }
            problems_title {
              text
            }
            problem_options {
              label {
                text
              }
              value {
                text
              }
            }
            title {
              text
            }
          }
        }
      }
    `
  );
  return prismicRepair.data;
};
