import React, { FC, useState, useCallback, useRef, isValidElement, cloneElement, Children } from "react";
import styled from "styled-components";
import cn from "classnames";
import { ArrowDown } from "../icons";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const StyledSelect = styled.div`
  position: relative;
  padding: 22px;
  text-align: left;
  border: 1px solid #cbcbcb;
  border-radius: 16px;
  cursor: pointer;
  min-height: 64px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  &:focus {
    box-shadow: 0 0 3pt 2pt red;
  }

  @media (max-width: 767.98px) {
    padding: 15px;
    line-height: 18px;
    min-height: 48px;
  }

  &:hover {
    border: 1px solid #808080;

    .select__list {
      border: 1px solid #808080;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }

  &.select--opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select__icon {
    margin: 0;
    position: absolute;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);

    @media (max-width: 767.98px) {
      right: 15px;
    }

    path {
      stroke: #000000;
    }

    &--opened {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .select__input-text p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  .select__list {
    position: absolute;
    border: 1px solid #cbcbcb;
    background: #f8f8f8;
    border-radius: 16px;
    width: calc(100% + 2px);
    left: -1px;
    top: 64px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    z-index: 10;
    max-height: 380px;
    overflow: auto;

    @media (max-width: 767.98px) {
      top: 48px;
      max-height: 240px;
    }

    &-item {
      padding: 22px;

      @media (max-width: 767.98px) {
        padding: 15px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }

      &:hover:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &--active {
        background: rgba(0, 0, 0, 0.08);

        &:hover {
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  defaultValue?: string;
  value: string | { [key: string]: boolean };
  multiple?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

const Select: FC<Props> = ({ children, value, className, multiple, placeholder, onChange }) => {
  const [opened, setOpened] = useState(false);

  const handleToggle = useCallback(() => setOpened(!opened), [opened]);
  const handleClose = useCallback(() => setOpened(false), []);

  const selectRef = useRef<HTMLDivElement>(null);
  useOutsideClick(selectRef, handleClose);

  const handleChangeValue = useCallback(
    (value: string) => {
      onChange(value);
      setOpened(!opened);
    },
    [opened]
  );

  const getLabel = () => {
    let label = placeholder || "";

    if (multiple) {
      React.Children.forEach(children, child => {
        if (isValidElement(child) && typeof value === "object" && value[child.props.value]) {
          label = label !== "" && label !== placeholder ? `${label}, ${child.props.children}` : child.props.children;
        }
      });
    }

    React.Children.forEach(children, child => {
      if (isValidElement(child) && value === child.props.value) {
        label = child.props.children;
      }
    });

    return label;
  };

  return (
    <StyledSelect className={cn(className, { "select--opened": opened })} onClick={handleToggle} ref={selectRef}>
      <div className="select__input">
        <div className="select__input-text">
          <p>{getLabel()}</p>
        </div>
        <ArrowDown className={cn("select__icon", { "select__icon--opened": opened })} />
      </div>
      {opened && (
        <ul className="select__list">
          {Children.map(children, child => {
            if (!isValidElement(child)) {
              return child;
            }

            return cloneElement(<li>{child.props.children}</li>, {
              onClick: () => handleChangeValue(child.props.value),
              className: cn("select__list-item", {
                "select__list-item--active":
                  multiple && typeof value === "object" ? value[child.props.value] : child.props.value === value
              })
            });
          })}
        </ul>
      )}
    </StyledSelect>
  );
};

export { Select };
