import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import find from "lodash/find";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import { useRepairPageData } from "../../hooks/useRepairPageData";
import { PreliminaryPrice } from "../../components/preliminaryprice/preliminaryprice";
import { Select } from "../../components/select/select";
import { Modal } from "../../components/modal";
import { RequestCallModal } from "../homepage/RequestCallModal";
import Option from "../../components/option";

const StylesRepairPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 80px 32px;

  @media (max-width: 991.98px) {
    flex-direction: column;
    padding: 40px 80px 64px;
  }

  @media (max-width: 767.98px) {
    padding: 28px 16px;
    flex-direction: column-reverse;
  }

  .repair-page__content {
    max-width: 556px;
    width: 100%;
    margin-left: 80px;

    @media (max-width: 991.98px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  .repair-page__title-block {
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .repair-page__title-block--tablet {
    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  .repair-page__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
  }

  .repair-page__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;

    color: #5b5b5b;
  }

  .repair-page__block {
    margin-top: 64px;

    @media (max-width: 991.98px) {
      margin-top: 48px;
    }

    @media (max-width: 767.98px) {
      margin-top: 0;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 32px;

      @media (max-width: 767.98px) {
        display: none;
      }
    }

    &-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 12px;

      @media (max-width: 767.98px) {
        display: none;
      }

      & > * {
        width: calc((100% - 20px) / 2);
        margin-top: 20px;

        &:last-child {
          width: 100%;
        }
      }
    }

    &-info {
      font-size: 16px;
      line-height: 24px;
      color: #808080;

      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }

  .repair-page__problems-select {
    margin-top: 12px;

    @media (min-width: 768px) {
      display: none;
    }
  }
`;

interface Model {
  model: {
    text: string;
  };
}

interface Problem_Option {
  label: { text: string };
  value: { text: string };
}

interface ProblemOption {
  label: string;
  value: string;
}

const DEFAULT_PROBLEM_MESSAGE = "Мы проведем бесплатную диагностику вашего устройства";

const setInitialProblems = (options: ProblemOption[]) =>
  fromPairs(map(options, (item: ProblemOption) => [item.value, false]));

const RepairPage = () => {
  const {
    description,
    info,
    phone_models,
    phone_title,
    preliminary_cost_block_image,
    preliminary_cost_block_title,
    problems_title,
    problem_options,
    title
  } = useRepairPageData();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
  const handleCloseModal = useCallback(() => setModalIsOpen(false), []);

  const initialPhoneModel = phone_models[0].model.text;

  const problemOptions = useMemo(
    () =>
      problem_options.map((option: Problem_Option) => ({
        value: option.value.text,
        label: option.label.text
      })),
    [problem_options]
  );

  const [phoneModel, setPhoneModel] = useState(initialPhoneModel);
  const handleSetPhoneModel = useCallback(value => setPhoneModel(value), []);

  const activePhoneModel = find(phone_models, ["model.text", phoneModel]);
  const [phoneProblems, setPhoneProblems] = useState(() => setInitialProblems(problemOptions));

  const checkProblems = useCallback(
    (value: string) => {
      if (value === "another") {
        return setPhoneProblems(prevState => ({
          ...setInitialProblems(problemOptions),
          [value]: !prevState[value]
        }));
      }

      if (value !== "another" && phoneProblems.another) {
        return setPhoneProblems(prevState => ({
          ...prevState,
          [value]: !prevState[value],
          another: false
        }));
      }

      setPhoneProblems(prevState => ({
        ...prevState,
        [value]: !prevState[value]
      }));
    },
    [phoneProblems, problemOptions]
  );

  const handleSetPhoneProblems = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.persist();
      const value = event.currentTarget.value;
      checkProblems(value);
    },
    [checkProblems]
  );

  const handleSelectPhoneProblems = useCallback((value: string) => checkProblems(value), [checkProblems]);

  const usedPhoneProblems = keys(pickBy(phoneProblems));

  const content = !phoneProblems.another
    ? usedPhoneProblems.reduce((sum: number, current: string) => sum + activePhoneModel[current], 0)
    : DEFAULT_PROBLEM_MESSAGE;

  return (
    <StylesRepairPage>
      <div className="repair-page__title-block--tablet">
        <h3 className="repair-page__title">{title.text}</h3>
        <p className="repair-page__description">{description.text}</p>
      </div>
      <PreliminaryPrice
        image={preliminary_cost_block_image.url}
        title={preliminary_cost_block_title.text}
        content={content}
        onRequestCall={handleOpenModal}
      />
      <div className="repair-page__content">
        <div className="repair-page__title-block">
          <h3 className="repair-page__title">{title.text}</h3>
          <p className="repair-page__description">{description.text}</p>
        </div>
        <div className="repair-page__block">
          <p className="repair-page__block-title">{phone_title.text}</p>
          <Select value={phoneModel} onChange={handleSetPhoneModel}>
            {phone_models.map(({ model }: Model) => (
              <option key={model.text} value={model.text}>
                {model.text}
              </option>
            ))}
          </Select>
        </div>
        <div className="repair-page__block">
          <p className="repair-page__block-title">{problems_title.text}</p>
          <div className="repair-page__block-options">
            {problemOptions.map((problemOption: ProblemOption) => (
              <Option
                label={problemOption.label}
                type="checkbox"
                key={problemOption.value}
                value={problemOption.value}
                checked={phoneProblems[problemOption.value]}
                onChange={handleSetPhoneProblems}
              />
            ))}
          </div>
        </div>
        <Select
          placeholder="Что сломалось"
          multiple
          value={phoneProblems}
          onChange={handleSelectPhoneProblems}
          className="repair-page__problems-select"
        >
          {problemOptions.map((problemOption: ProblemOption) => (
            <option key={problemOption.value} value={problemOption.value}>
              {problemOption.label}
            </option>
          ))}
        </Select>
        <div className="repair-page__block">
          <span className="repair-page__block-info">{info.text}</span>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <RequestCallModal />
      </Modal>
    </StylesRepairPage>
  );
};

export { RepairPage };
