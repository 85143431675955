import React from "react";
import styled from "styled-components";
import cn from "classnames";

interface Props {
  label: string;
  value: string;
  checked: boolean;
  type: "checkbox" | "radio";
  className?: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const StyledOption = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  height: 64px;
  cursor: pointer;
  border: 1px solid #cbcbcb;
  border-radius: 16px;
  color: #808080;

  &.checked {
    border: 1px solid #2c6dec;
    color: #000000;

    &:hover {
      border: 1px solid #2c6dec;
    }
  }

  &:hover {
    border: 1px solid #808080;
  }

  input {
    appearance: none;
  }
`;

const Option: React.FC<Props> = ({ label, className, checked, ...rest }) => {
  return (
    <StyledOption className={cn(className, "option", { checked })}>
      {label}
      <input checked={checked} {...rest} />
    </StyledOption>
  );
};

export default Option;
