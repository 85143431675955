import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout";
import { RepairPage } from "../modules/repairpage/repairpage";
import "../styles/1.reset.scss";
import "../styles/2.main.scss";

export default () => {
  return (
    <div>
      <Helmet>
        <title>Расчет стоимости ремонта</title>
      </Helmet>
      <Layout>
        <RepairPage />
      </Layout>
    </div>
  );
};
